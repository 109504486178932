<template>
  <div>

  </div>
</template>

<script>
import {getUnionId, getUserId} from "../../../utils/store";

export default {
  name: "Entry",
  mounted() {
    let user_id = getUserId()
    let union_id = getUnionId()
    if (user_id && user_id != '' && union_id && union_id != '') {
      this.$router.replace({
        path: '/home'
      })
      return
    }
    this.gotAuth()
  },
  methods: {
    gotAuth() {
      const appId = 'wxda915e8dce4fe609'
      const redirectUrl = 'https://login.kenengxueyuan.com/login'
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1'
    }
  }
}
</script>

<style scoped>

</style>
